import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import '../../styles/Signup.css'; // New CSS file for styling

const countryCodes = [
  { code: '+44', name: 'United Kingdom' },
  { code: '+1', name: 'United States' },
  { code: '+91', name: 'India' },
  { code: '+61', name: 'Australia' },
  { code: '+81', name: 'Japan' },
  { code: '+49', name: 'Germany' },
  { code: '+33', name: 'France' },
  { code: '+39', name: 'Italy' },
  { code: '+971', name: 'United Arab Emirates' },
  { code: '+1', name: 'Canada' },
];

const SignupPage = () => {
  const [formData, setFormData] = useState({ email: '', password: '', phoneNumber: '', countryCode: '+1' });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    const { email, password, phoneNumber, countryCode } = formData;

    if (!email || !password || !phoneNumber) {
      setError('All fields are required.');
      setLoading(false);
      return;
    }

    const fullPhoneNumber = `${countryCode}${phoneNumber}`; // Combine country code and phone number

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, phoneNumber: fullPhoneNumber }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.error || 'An error occurred during signup.');
        setLoading(false);
        return;
      }

      localStorage.setItem('token', data.token);
      navigate('/onboarding/step1');
    } catch (err) {
      console.error('Signup error:', err);
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-wrapper">
        <form onSubmit={handleSignup} className="signup-form">
          <h2 className="signup-title">Create Your Account</h2>
          {error && <p className="error-message">{error}</p>}
          
          <div className="form-group">
            <label htmlFor="email" className="form-label">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="you@example.com"
              className="form-input"
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="password" className="form-label">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Min. 8 characters"
              className="form-input"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
            <div className="phone-number-container">
              <select
                name="countryCode"
                value={formData.countryCode}
                onChange={handleInputChange}
                className="country-code-dropdown"
              >
                {countryCodes.map(({ code, name }) => (
                  <option key={code} value={code}>
                    {name} ({code})
                  </option>
                ))}
              </select>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                placeholder="123-456-7890"
                className="phone-number-input"
                required
              />
            </div>
          </div>
          
          <button 
            type="submit" 
            className="signup-button" 
            disabled={loading}
          >
            {loading ? 'Creating Account...' : 'Sign Up'}
          </button>
          
          <div className="signup-footer">
            <p>Already have an account? 
              <Link to="/login" className="login-link"> Log in</Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupPage;
