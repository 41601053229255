import React, { useState } from 'react';
import './FeedbackWidget.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';

const FeedbackWidget = () => {
  const [showForm, setShowForm] = useState(false);
  const [feedbackType, setFeedbackType] = useState('bug'); // Default to Bug
  const [where, setWhere] = useState('home'); // Default to "home" page
  const [description, setDescription] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}/api/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: feedbackType,
          where,
          description        }),
      });

      if (response.ok) {
        setSuccess(true);
        setTimeout(() => {
          setShowForm(false);
          setSuccess(false);
          setDescription('');
          setWhere('home');
        }, 2000);
      } else {
        alert('Failed to send feedback. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Error submitting feedback.');
    }
  };

  return (
    <>
      {/* Floating Circular Button */}
      <div className="feedback-widget">
        <button onClick={() => setShowForm(true)}>Feedback</button>
      </div>

      {/* Feedback Form */}
      {showForm && (
        <div className="feedback-overlay">
          <div className="feedback-form">
            <button className="close-button" onClick={() => setShowForm(false)}>&times;</button>
            <h3>Send Feedback</h3>
            <form onSubmit={handleSubmit}>
              <label>
                Type:
                <select value={feedbackType} onChange={(e) => setFeedbackType(e.target.value)}>
                  <option value="bug">Bug Report</option>
                  <option value="feature">Feature Request</option>
                </select>
              </label>
              <label>
                Where:
                <select value={where} onChange={(e) => setWhere(e.target.value)}>
                  <option value="pop-up">Pop-up</option>
                  <option value="home">Home Page</option>
                  <option value="calendar">Calendar Page</option>
                  <option value="bookings">Bookings Page</option>
                  <option value="services">Services Page</option>
                  <option value="settings">Settings Page</option>
                  <option value="pop-up">Pop-up</option>
                </select>
              </label>
              <label>
                Description:
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Describe the issue or feature here..."
                  required
                ></textarea>
              </label>
              <button type="submit">Submit</button>
            </form>
            {success && <p className="success-message">Thank you for your feedback!</p>}
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackWidget;
