import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Step3.css';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080'; // Environment-aware

const Step3 = () => {
  const [address, setAddress] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleAddressInput = async (input) => {
    setAddress(input);
    if (input.length >= 3) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/business/places?input=${input}`);
        if (!response.ok) throw new Error('Failed to fetch address suggestions');
        const suggestions = await response.json();
        setAddressSuggestions(suggestions || []);
      } catch (error) {
        console.error('Error fetching address suggestions:', error);
        setAddressSuggestions([]);
      }
    } else {
      setAddressSuggestions([]);
    }
  };

  const handleNext = async () => {
    setError(null);
    setLoading(true);

    if (!houseNumber || !address) {
      setError('Please enter your house number and select an address.');
      setLoading(false);
      return;
    }

    const fullAddress = `${houseNumber}, ${address}`;
    try {
      const response = await fetch(`${API_BASE_URL}/api/onboarding/step3`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ address: fullAddress }),
      });

      if (response.ok) {
        console.log('Address saved successfully!');
        navigate('/dashboard'); // Navigate to the dashboard after onboarding
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Failed to save address.');
      }
    } catch (err) {
      console.error('Error saving address:', err);
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Step 3: Set Your Business Address</h2>
      <div>
        <h3>Add your business location so your clients can easily find you.</h3>
        {error && <p className="error-message">{error}</p>}

        <div className="form-group">
          <label htmlFor="address">Search Address</label>
          <input
            type="text"
            id="address"
            value={address}
            onChange={(e) => handleAddressInput(e.target.value)}
            placeholder="Start typing your postcode"
            required
          />
          {addressSuggestions.length > 0 && (
            <ul className="address-suggestions">
              {addressSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => setAddress(suggestion.description)}
                  className="address-option"
                >
                  {suggestion.description}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="houseNumber">House Number</label>
          <input
            type="text"
            id="houseNumber"
            value={houseNumber}
            onChange={(e) => setHouseNumber(e.target.value)}
            placeholder="Enter your house or apartment number"
            required
          />
        </div>

      </div>

      <button onClick={handleNext} disabled={loading}>
        {loading ? 'Saving...' : 'Next'}
      </button>
    </div>
  );
};

export default Step3;
