import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Step2.css';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080'; // Environment-aware

const Step2 = () => {
  const [niche, setNiche] = useState('');
  const [otherNiche, setOtherNiche] = useState(''); // State for manually entered niche
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const businessNiches = [
    { id: 'car-detailing', title: 'Car Detailing', icon: '🚗' },
    { id: 'roof-cleaning', title: 'Roof Cleaning', icon: '🏠' },
    { id: 'pool-cleaning', title: 'Pool Cleaning', icon: '🏊‍♂️' },
    { id: 'lawn-care', title: 'Lawn Care and Landscaping', icon: '🌱' },
    { id: 'home-cleaning', title: 'Home Cleaning', icon: '🧹' },
    { id: 'painting', title: 'Painting', icon: '🎨' },
    { id: 'handyman', title: 'Handyman Services', icon: '🛠️' },
    { id: 'pet-grooming', title: 'Mobile Pet Grooming', icon: '🐾' },
    { id: 'junk-removal', title: 'Junk Removal', icon: '🗑️' },
    { id: 'pest-control', title: 'Pest Control', icon: '🐜' },
    { id: 'other', title: 'Other', icon: '➕' }, // "Other" option
  ];

  const handleNext = async () => {
    setError(null);
    setLoading(true);

    // Use `otherNiche` if "Other" is selected
    const selectedNiche = niche === 'other' ? otherNiche : niche;

    try {
      const response = await fetch(`${API_BASE_URL}/api/onboarding/step2`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ niche: selectedNiche }),
      });

      if (response.ok) {
        localStorage.setItem('niche', selectedNiche); // Store the niche in local storage
        console.log(`Niche "${selectedNiche}" saved in local storage.`);
        navigate('/onboarding/step3'); // Move to Step 3
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Failed to save niche.');
      }
    } catch (err) {
      console.error('Error saving niche:', err);
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Step 2: Select Your Niche</h2>
      <div>
        <h3>What is your business niche?</h3>
        {error && <p className="error-message">{error}</p>}
        <div className="niche-grid">
          {businessNiches.map((businessNiche) => (
            <div
              key={businessNiche.id}
              className={`niche-box ${niche === businessNiche.id ? 'selected' : ''}`}
              onClick={() => {
                setNiche(businessNiche.id);
                setOtherNiche(''); // Clear otherNiche input if a predefined niche is selected
              }}
            >
              <div className="niche-icon">{businessNiche.icon}</div>
              <h3>{businessNiche.title}</h3>
            </div>
          ))}
        </div>
        {niche === 'other' && (
          <div className="other-niche-input">
            <label htmlFor="otherNiche">Please specify your business:</label>
            <input
              type="text"
              id="otherNiche"
              value={otherNiche}
              onChange={(e) => setOtherNiche(e.target.value)}
              placeholder="Enter your business niche"
              required
            />
          </div>
        )}
      </div>
      <button onClick={handleNext} disabled={(!niche || (niche === 'other' && !otherNiche)) || loading}>
        {loading ? 'Saving...' : 'Next'}
      </button>
    </div>
  );
};

export default Step2;
