import React, { useState, useEffect } from 'react';
import '../../styles/Settings.css';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080'; // Environment-aware

const Settings = () => {
  const [businessHours, setBusinessHours] = useState([]);
  const [businessAddress, setBusinessAddress] = useState('');
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchBusinessHours();
    fetchBusinessAddress();
  }, []);

  const fetchBusinessHours = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/availability`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch business hours');
      const data = await response.json();

      if (!data || data.length === 0) {
        // Default availability if no data exists in the backend
        setBusinessHours([
          { day: 'Monday', enabled: true, start: '10:00', end: '19:00' },
          { day: 'Tuesday', enabled: true, start: '10:00', end: '19:00' },
          { day: 'Wednesday', enabled: true, start: '10:00', end: '19:00' },
          { day: 'Thursday', enabled: true, start: '10:00', end: '19:00' },
          { day: 'Friday', enabled: true, start: '10:00', end: '19:00' },
          { day: 'Saturday', enabled: false, start: '10:00', end: '19:00' },
          { day: 'Sunday', enabled: false, start: '10:00', end: '19:00' },
        ]);
      } else {
        const formattedData = data.map((day) => ({
          day: day.day,
          enabled: !!day.startTime && !!day.endTime,
          start: day.startTime || '10:00',
          end: day.endTime || '19:00',
        }));
        setBusinessHours(formattedData);
      }
    } catch (error) {
      console.error('Error fetching business hours:', error);
      setErrorMessage('Failed to load business hours.');
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };

  const fetchBusinessAddress = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/address`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch business address');
      const data = await response.json();
      setBusinessAddress(data.address || 'No address set');
    } catch (error) {
      console.error('Error fetching business address:', error);
      setErrorMessage('Failed to load business address.');
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };

  const handleAddressInput = async (input) => {
    setBusinessAddress(input);
    if (input.length >= 3) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/business/places?input=${input}`);
        if (!response.ok) throw new Error('Failed to fetch address suggestions');
        const suggestions = await response.json();
        setAddressSuggestions(suggestions || []);
      } catch (error) {
        console.error('Error fetching address suggestions:', error);
      }
    } else {
      setAddressSuggestions([]);
    }
  };

  const handleAddressSave = async () => {
    try {
      console.log('Sending updated address:', businessAddress); // Debugging log
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/address/update`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ address: businessAddress }),
      });
  
      if (!response.ok) throw new Error('Failed to update business address');
      const data = await response.json();
      console.log('Backend response:', data); // Debugging log
  
      setSuccessMessage('Business address updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
  
      // Re-fetch the address to verify the update
      fetchBusinessAddress();
    } catch (error) {
      console.error('Error updating business address:', error);
      setErrorMessage('Failed to update business address. Please try again.');
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };
  

  const handleToggleDay = async (index) => {
    const updatedHours = [...businessHours];
    updatedHours[index].enabled = !updatedHours[index].enabled;
    setBusinessHours(updatedHours);
  
    // Prepare payload for only the toggled day
    const payload = {
      day: updatedHours[index].day,
      startTime: updatedHours[index].enabled ? updatedHours[index].start : null,
      endTime: updatedHours[index].enabled ? updatedHours[index].end : null,
    };
  
    console.log('Payload being sent for toggle:', payload);
  
    try {
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/availability/update-single`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Backend error response:', errorText);
        throw new Error('Failed to update business hours for the specific day');
      }
  
      const data = await response.json();
      console.log('Backend success response for toggle:', data);
  
      setSuccessMessage(`Business hours updated for ${payload.day}!`);
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error saving specific business hours:', error);
      setErrorMessage('Failed to update business hours. Please try again.');
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };
  
  
  
  

  const handleTimeChange = (index, timeType, value) => {
    const updatedHours = [...businessHours];
    updatedHours[index][timeType] = value;
    setBusinessHours(updatedHours);
  };

  const handleSave = async () => {
    try {
      const payload = businessHours.map((day) => ({
        day: day.day,
        startTime: day.enabled ? day.start : null, // Send null for disabled days
        endTime: day.enabled ? day.end : null,    // Send null for disabled days
      }));
  
      console.log('Payload being sent to backend:', JSON.stringify({ businessHours: payload }));
  
      const response = await fetch(`${API_BASE_URL}/api/business-dashboard/availability/update`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ businessHours: payload }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Backend error response:', errorText);
        throw new Error('Failed to update business hours');
      }
  
      const data = await response.json();
      console.log('Backend success response:', data);
  
      setSuccessMessage('Business hours updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
  
      fetchBusinessHours(); // Refresh frontend state
    } catch (error) {
      console.error('Error saving business hours:', error);
      setErrorMessage('Failed to update business hours. Please try again.');
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };
  
  
  
  

  const renderTimeOptions = () => {
    const options = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 30) {
        const time = `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
        options.push(<option key={time} value={time}>{time}</option>);
      }
    }
    return options;
  };

  return (
    <div className="settings-page">
      <h2>Business Settings</h2>
      {successMessage && <div className="success-message">{successMessage}</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <div className="business-address-container">
        <h3>Business Address</h3>
        {!isEditingAddress ? (
          <>
            <p>{businessAddress}</p>
            <button className="save-button" onClick={() => setIsEditingAddress(true)}>Edit Address</button>
          </>
        ) : (
          <>
            <input
              type="text"
              value={businessAddress}
              onChange={(e) => handleAddressInput(e.target.value)}
              placeholder="Search for your business address"
            />
            <ul className="address-suggestions">
              {addressSuggestions.map((suggestion, index) => (
                <li key={index} onClick={() => setBusinessAddress(suggestion.description)}>
                  {suggestion.description}
                </li>
              ))}
            </ul>
            <button className="save-button" onClick={handleAddressSave}>Save Address</button>
            <button className="cancel-button" onClick={() => setIsEditingAddress(false)}>Cancel</button>
          </>
        )}
      </div>

      <div className="business-hours-container">
        <h3>Business Hours</h3>
        {businessHours.map((day, index) => (
          <div key={day.day} className="business-hours-row">
            <div className="day-toggle">
              <label>{day.day}</label>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={day.enabled}
                  onChange={() => handleToggleDay(index)}
                />
                <span className="slider round"></span>
              </label>
            </div>

            <div className="time-select">
              <select
                value={day.start}
                onChange={(e) => handleTimeChange(index, 'start', e.target.value)}
                disabled={!day.enabled}
              >
                {renderTimeOptions()}
              </select>

              <select
                value={day.end}
                onChange={(e) => handleTimeChange(index, 'end', e.target.value)}
                disabled={!day.enabled}
              >
                {renderTimeOptions()}
              </select>
            </div>
          </div>
        ))}
      </div>

      <button className="save-button" onClick={handleSave}>Save Hours</button>
    </div>
  );
};

export default Settings;
