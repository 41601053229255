import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <nav className="navbar">
      <div className="navbar-links">
        <Link to="/dashboard" className="navbar-link">My Dashboard</Link>
        <Link to="/dashboard/calendar" className="navbar-link">Calendar</Link>
        <Link to="/dashboard/bookings" className="navbar-link">Bookings</Link>
        <Link to="/dashboard/services" className="navbar-link">Services</Link>
        <Link to="/dashboard/settings" className="navbar-link">Settings</Link>
      </div>
      <button className="logout-button" onClick={handleLogout}>
        Log Out
      </button>
    </nav>
  );
};

export default Navbar;
