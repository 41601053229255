import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import ShadowDOM from 'react-shadow';

const LandingPage = () => {
  const [activeFeature, setActiveFeature] = useState(null);

  const features = [
    {
      title: "Quick Integration",
      description: "Embed your custom booking pop-up in minutes.",
      icon: "🔌",
    },
    {
      title: "Set Up Your Services",
      description: "Easily configure your services, pricing, and durations.",
      icon: "🔧",
    },
    {
      title: "Specific Questions Setup",
      description: "Customize questions to capture precise customer needs for accurate quotes.",
      icon: "❓",
    },
    {
      title: "Instant & Custom Quote Generation",
      description: "Create tailored quotes that satisfy both you and your customers with ease.",
      icon: "⚡",
    },
    {
      title: (
        <>
          Distance-Based Pricing <span className="new-feature">New</span>
        </>
      ),
      description: "Incorporate distance costs seamlessly into your bookings.",
      icon: "📍",
    },
    {
      title: "Smart Scheduling",
      description: "Manage business hours, lunches, bookings, and reschedules effortlessly.",
      icon: "🗓️",
    }
  ];


  const screenshots = [
    { src: "/placeholder.svg?height=300&width=500", alt: "Smart Calendar Interface" },
    { src: "/placeholder.svg?height=300&width=500", alt: "Quote Generator Widget" },
    { src: "/placeholder.svg?height=300&width=500", alt: "Dashboard Overview" },
  ];

  const Carousel = ({ items }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
      }, 5000);
      return () => clearInterval(interval);
    }, [items.length]);

    return (
      <div className="carousel">
        {items.map((item, index) => (
          <div
            key={index}
            className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
          >
            <img src={item.src} alt={item.alt} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <ShadowDOM.div>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
          .new-feature {
  background-color: #ffcc00; /* Bright yellow */
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 5px;
  margin-left: 8px;
  vertical-align: middle;
}

          body {
            margin: 0;
            font-family: 'Inter', sans-serif;
            line-height: 1.6;
            color: #333;
            background-color: #ffffff;
          }
          .container {
            width: 100%;
            padding: 0;
          }
          .navbar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            background-color: #ffffff;
            color: #333;
            position: sticky;
            top: 0;
            z-index: 1000;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          }
            .logo-image {
  width: 80px; /* Set the width of the logo */
  height: auto; /* Maintain aspect ratio */
}
          .navbar a {
            color: #333;
            text-decoration: none;
            margin: 0 15px;
            font-size: 18px;
            transition: all 0.3s ease;
          }
          .navbar a:hover {
            color: #4a90e2;
          }
          .hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 20px;
}

.hero-content {
  flex: 1;
  text-align: left;
  padding-right: 20px;
}

.hero-image {
  flex: 1;
  max-width: 50%; /* Limit to half of the hero section */
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-image img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    align-items: center;
  }

  .hero-content {
    text-align: center;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .hero-image {
    max-width: 90%; /* Use more width on smaller screens */
  }
}

          .hero-carousel {
            flex: 1;
            max-width: 500px;
          }
          .carousel {
            position: relative;
            overflow: hidden;
            border-radius: 10px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
          }
          .carousel-item {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
          }
          .carousel-item.active {
            opacity: 1;
          }
          .carousel-item img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
          .hero h1 {
            font-size: 56px;
            margin-bottom: 20px;
            animation: fadeInUp 1s ease-out;
            color: #333;
          }

          .hero h2 {
            font-size: 30px;
            margin-bottom: 20px;
            animation: fadeInUp 1s ease-out;
            color: #333;
          }
          .hero p {
            font-size: 15px;
            margin-bottom: 40px;
            animation: fadeInUp 1s ease-out 0.5s both;
            color: #666;
          }
          .hero button {
            background-color: #4a90e2;
            color: white;
            border: none;
            padding: 15px 30px;
            border-radius: 30px;
            font-size: 18px;
            cursor: pointer;
            transition: all 0.3s ease;
            animation: fadeInUp 1s ease-out 1s both;
          }
          .hero button:hover {
            background-color: #3a7bd5;
            transform: translateY(-3px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
          .features {
            padding: 80px 20px;
            text-align: center;
            background-color: #ffffff;
          }
          .features h2 {
            font-size: 40px;
            margin-bottom: 40px;
            color: #333;
          }
          .features-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 30px;
            padding: 0 20px;
          }
          .feature {
            background: #ffffff;
            padding: 30px;
            border-radius: 15px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
            transition: all 0.3s ease;
            cursor: pointer;
          }
          .feature:hover {
            transform: translateY(-10px);
            box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
          }
          .feature h3 {
            font-size: 28px;
            margin-bottom: 15px;
            color: #333;
          }
          .feature-icon {
            font-size: 48px;
            margin-bottom: 20px;
            transition: transform 0.3s ease;
          }
          .feature-icon.active {
            transform: scale(1.2);
          }
          .feature p {
            color: #666;
          }
          .screenshots {
            padding: 80px 20px;
            text-align: center;
            background-color: #fff;
          }
          .screenshots h2 {
            font-size: 40px;
            margin-bottom: 40px;
            color: #3a1c71;
          }
          .screenshots img {
            max-width: 90%;
            border-radius: 15px;
            box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
            margin-bottom: 30px;
            transition: all 0.3s ease;
          }
          .screenshots img:hover {
            transform: scale(1.05);
          }
          .testimonials {
            padding: 80px 20px;
            text-align: center;
            background-color: #f9f9f9;
          }
          .testimonials h2 {
            font-size: 40px;
            margin-bottom: 40px;
            color: #3a1c71;
          }
          .testimonial {
            background: white;
            padding: 30px;
            border-radius: 15px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
            margin-bottom: 30px;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
          }
          .testimonial p {
            font-size: 18px;
            font-style: italic;
            margin-bottom: 20px;
          }
          .pricing {
            padding: 80px 20px;
            text-align: center;
            background-color: #fff;
          }
          .pricing h2 {
            font-size: 40px;
            margin-bottom: 40px;
            color: #3a1c71;
          }
          .pricing-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 30px;
            padding: 0 20px;
          }
          .pricing-plan {
            background: #f9f9f9;
            padding: 40px;
            border-radius: 15px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease;
          }
          .pricing-plan:hover {
            transform: translateY(-10px);
            box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
          }
          .pricing-plan h3 {
            font-size: 28px;
            margin-bottom: 20px;
            color: #3a1c71;
          }
          .pricing-plan p {
            font-size: 24px;
            margin-bottom: 30px;
            color: #d76d77;
          }
          .pricing-plan button {
            background-color: #3a1c71;
            color: white;
            border: none;
            padding: 15px 30px;
            border-radius: 30px;
            font-size: 18px;
            cursor: pointer;
            transition: all 0.3s ease;
          }
          .pricing-plan button:hover {
            background-color: #d76d77;
            transform: translateY(-3px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
          .cta {
            text-align: center;
            padding: 100px 20px;
            background-color: #4a90e2;
            color: white;
          }
          .cta h2 {
            font-size: 40px;
            margin-bottom: 30px;
          }
          .cta button {
            background-color: white;
            color: #3a1c71;
            border: none;
            padding: 15px 30px;
            border-radius: 30px;
            font-size: 18px;
            cursor: pointer;
            transition: all 0.3s ease;
          }
          .cta button:hover {
            background-color: #ffcc00;
            transform: translateY(-3px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
          .footer {
            background-color: #f9f9f9;
            color: #3a1c71;
            padding: 40px 20px;
            text-align: center;
          }
          .footer-content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            max-width: 1200px;
            margin: 0 auto;
          }
          .footer-section {
            flex: 1;
            margin-bottom: 20px;
            min-width: 200px;
          }
          .footer-section h3 {
            margin-bottom: 15px;
          }
          .footer-section a {
            color: #4a90e2;
            text-decoration: none;
            display: block;
            margin-bottom: 10px;
          }
          .footer-bottom {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
          }
          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
          .social-proof {
            padding: 80px 20px;
            text-align: center;
            background-color: white;
          }
          .social-proof h2 {
            font-size: 32px;
            margin-bottom: 40px;
            color: #3a1c71;
          }
          .testimonials {
            display: flex;
            justify-content: center;
            gap: 30px;
            margin-bottom: 40px;
          }
          .testimonial {
            background: #f9f9f9;
            padding: 20px;
            border-radius: 10px;
            max-width: 400px;
          }
          .testimonial p {
            font-style: italic;
            margin-bottom: 10px;
          }
          .testimonial .author {
            font-weight: bold;
          }
          .metrics {
            display: flex;
            justify-content: center;
            gap: 60px;
          }
          .metric h3 {
            font-size: 48px;
            color: #4a90e2;
            margin-bottom: 10px;
          }
          .cta .subtext {
            margin-top: 10px;
            font-size: 14px;
            color: #f0f0f0;
          }
          .footer-section p {
            margin-bottom: 10px;
          }
        `}
      </style>

      <div className="container">
        {/* Navigation Bar */}
        <nav className="navbar">
          <div className="logo-placeholder">
            {/* Logo will go here */}
            <img src="image.png" alt="Logo" className="logo-image" />
          </div>
          <div>
            {/*<a href="#features">Features</a>*/}
            {/*<a href="#screenshots">Screenshots</a>*/}
            <Link to="/login">Login</Link>
            <Link to="/signup">Sign Up</Link>
            </div>
        </nav>


        {/* Hero Section */}
        <div className="hero">
          <div className="hero-content">
            <h1>Supercharge Your Service Business</h1>

            <h2>Streamline quotations, operations and boost efficiency with our all-in-one management platform.</h2>
            <button> Get Qwotly For Free</button>
            <p>No credit card required. Set up is a breeze.</p>

          </div>
          <div className="hero-image">
            <img src="/19.png" alt="Smart Calendar Interface" />
          </div>
        </div>
        {/* Description Section */}
        <div id="features" className="features">
          <h2>What is Qwotly?</h2>
          <h3>
            Qwotly is your ultimate business partner. It streamlines the way you generate quotes, manage bookings, and engage with customers. Designed to save you time and maximize your efficiency, Qwotly eliminates the back-and-forth and helps you deliver exceptional service while growing your business effortlessly.
          </h3>
        </div>

        {/* Features Section */}
        <div id="features" className="features">
          <h2>Powerful Features to Boost Your Business</h2>
          <div className="features-grid">
            {features.map((feature, index) => (
              <div
                key={index}
                className="feature"
                onMouseEnter={() => setActiveFeature(index)}
                onMouseLeave={() => setActiveFeature(null)}
              >
                <div className={`feature-icon ${activeFeature === index ? 'active' : ''}`}>{feature.icon}</div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Social Proof Section */}
        <div className="social-proof">
          <h2>Modernise your business</h2>

          <div className="metrics">

            <div className="metric">
              <h3>85%</h3>
              <p>Reduction in Quote Response Time</p>
            </div>
            <div className="metric">
              <h3>3x</h3>
              <p>Increase in Customer Satisfaction</p>
            </div>
          </div>
        </div>

        {/* Screenshots Section */}
        <div id="screenshots" className="screenshots">
          <h2>See Qwotly in Action</h2>
          <img src="/19.png" alt="Smart Calendar Interface" />
          <img src="/18.png" alt="Quote Generator Widget" />
        </div>

        {/* Call-to-Action Section */}
        <div id="contact" className="cta">
          <h2>Ready to Transform Your Business?</h2>
          <button>Get Started in 2 Minutes - It's Free!</button>
          <p className="subtext">No credit card required. Set up is a breeze.</p>
        </div>

        {/* Footer */}
        <footer className="footer">
          <div className="footer-content">
            <div className="footer-section">
              <h3>Qwotly</h3>
              <p>Empowering home service businesses with smart technology solutions.</p>
            </div>
            <div className="footer-section">
              <h3>Quick Links</h3>
              <a href="#features">Features</a>
              <a href="#contact">Contact</a>
              <a href="#">Privacy Policy</a>
              <a href="#">Terms of Service</a>
            </div>
            <div className="footer-section">
              <h3>Connect</h3>
              <a href="#">Facebook</a>
              <a href="#">Twitter</a>
              <a href="#">LinkedIn</a>
              <a href="#">Instagram</a>
            </div>
            <div className="footer-section">
              <h3>Contact Us</h3>
              <p>Email: support@qwotly.com</p>
              <p>Phone: +44 784 554 5165</p>
            </div>
          </div>
          <div className="footer-bottom">
            <p>&copy; 2024 Qwotly. All Rights Reserved.</p>
          </div>
        </footer>
      </div>
    </ShadowDOM.div>
  );
};

export default LandingPage;
