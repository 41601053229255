import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080'; // Environment-aware

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Store user info
  const [loading, setLoading] = useState(true); // Track loading state
  const navigate = useNavigate();

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const isExpired = decoded.exp * 1000 < Date.now();
        if (isExpired) {
          console.log('Token expired. Logging out.');
          logout();
        } else {
          setUser(decoded);
        }
      } catch (err) {
        console.error('Invalid token:', err);
        logout();
      }
    }
    setLoading(false); // Ensure loading is set to false after checking
  }, [logout]);

  const login = async (email, password) => {
    try {
      console.log('Attempting login with:', { email, password });
      const response = await fetch(`${API_BASE_URL}/api/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });
  
      if (response.ok) {
        const { token } = await response.json();
        localStorage.setItem('token', token);
        const decoded = jwtDecode(token);
        console.log('Decoded token:', decoded);
        setUser(decoded);
        navigate('/dashboard');
      } else {
        const errorData = await response.json();
        console.error('Login failed with error:', errorData.error);
        throw new Error(errorData.error || 'Login failed');
      }
    } catch (err) {
      console.error('Error during login:', err.message);
      throw err;
    }
  };
  
  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
