import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './routes/ProtectedRoute';
import Login from './pages/Auth/Login';
import Signup from './pages/Auth/Signup';
import Dashboard from './pages/Dashboard/Dashboard';
import Overview from './pages/Dashboard/Overview';
import Calendar from './pages/Dashboard/Calendar';
import Bookings from './pages/Dashboard/Bookings';
import Services from './pages/Dashboard/Services';
import Settings from './pages/Dashboard/Settings';
import Step1 from './pages/Dashboard/Onboarding/Step1';
import Step2 from './pages/Dashboard/Onboarding/Step2';
import Step3 from './pages/Dashboard/Onboarding/Step3';
import Reschedule from './pages/Customer/reschedule';
import Cancel from './pages/Customer/cancel'; // Adjust path as needed
import FeedbackWidget from './components/FeedbackWidget';
import LandingPage from './pages/Home/LandingPage'; // Import LandingPage


import './styles/index.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} /> {/* Landing Page Route */}

          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />

          {/* Onboarding Routes */}
          <Route path="/onboarding/step1" element={<Step1 />} />
          <Route path="/onboarding/step2" element={<Step2 />} />
          <Route path="/onboarding/step3" element={<Step3 />} /> {/* Add Step 3 */}
          <Route path="/customer/reschedule" element={<Reschedule />} />
          <Route path="/customer/cancel" element={<Cancel />} />
          {/* Protected Dashboard Routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            <Route index element={<Overview />} /> {/* Default to Overview */}
            <Route path="calendar" element={<Calendar />} /> {/* Updated to use new Calendar */}
            <Route path="bookings" element={<Bookings />} />
            <Route path="services" element={<Services />} />
            <Route path="settings" element={<Settings />} />
          </Route>
        </Routes>
        <FeedbackWidget />

      </AuthProvider>
    </Router>
  );
}

export default App;